import jwt_decode from "jwt-decode";  
export * from "./helper"
import moment from 'moment'

function GetToken(){
    const token = localStorage.getItem('token');
    if (token === "undefined"){
        localStorage.removeItem('token')
        return false
    } 
    
    if(token === null){
        return false
    } 
    const jwtDecode = jwt_decode(token)    
    if (jwtDecode.default_language === undefined || jwtDecode.default_language === "undefined"){
        localStorage.removeItem('token')
        return false
    }

    return token
}
export const User = () => {
    const tempUser = localStorage.getItem('user');
    if (tempUser === "undefined"){
        localStorage.removeItem('user')
        return null
    } 
    
    if(tempUser === null){
        return null
    } 
    
    return JSON.parse(tempUser);
}
export const isLogin = () => {
    const token = GetToken();   
    if (token) {
        const tokenExpiration = jwt_decode(token).exp;        
        if (tokenExpiration > new Date().getTime() / 1000) {
            return true;
        }else{
            //console.log('tokenExpiration')
        }
    }
    return false;
}

export const isUserFrench = () => {
    const token = GetToken();   
    if (token) { 
        if (jwt_decode(token).default_language == "fr") {             
            return true;
        }
    }
    return false;
}

export const isUserInter = () => {
    const token = GetToken();     
    if (token) { 
        if (jwt_decode(token).default_language != "fr") {             
            return true;
        }
    }
    return false;
}

export const homePage = () => {
    let path = "/";
    if(isUserFrench()){
        path = "/simple"
    }else if(isUserInter()){ 
        path = "/inter"
    }
    return path
}

export const currentDate = (formate = "YYYY-MM-DD") => {
    return moment(new Date()).format(formate)
}

export const isAdmin = () => {
    const token = GetToken();  
    if (token) { 
        if (jwt_decode(token).isAdmin) {             
            return true;
        }
    }
    return false;
}

export const getBrowserLang = () => {
    if(navigator.language.includes('fr'))
        return 'fr'
    
    return 'en'
}

/*

export const isUser = () => {
    const token = GetToken();  
    //console.log(jwt_decode(token))   
    if (token) { 
        if (jwt_decode(token).role == "USER") {             
            return true;
        }
    }
    return false;
}

export const isDev = () => {
    const token = GetToken();  
    //console.log(jwt_decode(token))   
    if (token) { 
        if (jwt_decode(token).role == "DEV") {             
            return true;
        }
    }
    return false;
}

export const isCustomer = () => {
    const token = GetToken();  
    //console.log(jwt_decode(token))   
    if (token) { 
        if (jwt_decode(token).role == "CUSTOMER") {             
            return true;
        }
    }
    return false;
}
*/
